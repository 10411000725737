import DifficultySlider from "./settings/DifficultySlider";
import HWKeyboardToggle from "./settings/HWKeyboardToggle";
import ZoomSelector from "./settings/ZoomSelector";
import Explanations from "./explanations/Explanations";

function InfoPage({
  enableHwKeyboard,
  setEnableHwKeyboard,
  zoomLevel,
  setZoomLevel,
  difficulty,
  setDifficulty,
}) {
  return (
    <>
      <h2>Settings</h2>
      <HWKeyboardToggle
        enableHwKeyboard={enableHwKeyboard}
        setEnableHwKeyboard={setEnableHwKeyboard}
      />

      <ZoomSelector zoom={zoomLevel} setZoomLevel={setZoomLevel} />

      <DifficultySlider difficulty={difficulty} setDifficulty={setDifficulty} />

      <Explanations />
    </>
  );
}

export default InfoPage;
