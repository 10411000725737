import React from "react";
import "./Gameboard.css"; // Add any specific styles for the gameboard
import { Difficulties } from "../settings/DifficultySlider";
import {
  SPECIAL_CHARS_UPPER,
  SPECIAL_CHARS_MIDDLE,
  SPECIAL_CHARS_LOWER,
  isAlphabetic,
} from "../helpers/utils";

// Function to check if any occurrences of a letter have been guessed but not all
const notAllOccurrencesGuessed = (letter, sentence, initialSentence) => {
  const upperLetter = letter.toUpperCase();
  const totalOccurrences = initialSentence
    .split("")
    .filter((char) => char.toUpperCase() === upperLetter).length;
  const guessedOccurrences = sentence
    .split("")
    .filter((char) => char.toUpperCase() === upperLetter).length;

  return guessedOccurrences < totalOccurrences;
};

const hasSomeOccurrencesGuessed = (letter, sentence, initialSentence) => {
  const upperLetter = letter.toUpperCase();
  const totalOccurrences = initialSentence
    .split("")
    .filter((char) => char.toUpperCase() === upperLetter).length;
  const guessedOccurrences = sentence
    .split("")
    .filter((char) => char.toUpperCase() === upperLetter).length;

  return guessedOccurrences > 0 && guessedOccurrences < totalOccurrences;
};

function Gameboard({
  sentence,
  letterMapping,
  selectedIndex,
  onSelectLetter,
  onGuessLetter,
  initialSentence,
  difficulty,
  zoomLevel,
  wrongIndex,
}) {
  const words = sentence.join("").split(" ");
  let ctr = 0;

  const isEasyNumber = (shouldShowNumber, hasSomeGuessed, originalLetter) => {
    if (shouldShowNumber) {
      if (difficulty === Difficulties.Easy && hasSomeGuessed) {
        return (
          <span className="letter-number easy">
            {letterMapping[originalLetter.toUpperCase()]}
          </span>
        );
      } else {
        return (
          <span className="letter-number">
            {letterMapping[originalLetter.toUpperCase()]}
          </span>
        );
      }
    }
  };

  function letterButton(
    letter,
    gindex,
    shouldShowNumber,
    hasSomeGuessed,
    originalLetter
  ) {
    if (isAlphabetic(letter) || letter === "_") {
      return (
        <div key={gindex} className={"letter-container"}>
          <button
            onClick={() => onSelectLetter(gindex)}
            disabled={letter !== "_"}
            className={
              (selectedIndex === gindex ? "selected" : "") +
              (wrongIndex === gindex ? " wrong" : "")
            }
          >
            {letter.toUpperCase()}
          </button>
          {isEasyNumber(shouldShowNumber, hasSomeGuessed, originalLetter)}
        </div>
      );
    } else if (SPECIAL_CHARS_UPPER.includes(letter)) {
      return (
        <div key={gindex} className="letter-container special upper">
          <div>{letter}</div>
        </div>
      );
    } else if (SPECIAL_CHARS_MIDDLE.includes(letter)) {
      return (
        <div key={gindex} className="letter-container special middle">
          <div>{letter}</div>
        </div>
      );
    } else if (SPECIAL_CHARS_LOWER.includes(letter)) {
      return (
        <div key={gindex} className="letter-container special lower">
          <div>{letter}</div>
        </div>
      );
    } else {
      if (process.env["NODE_ENV"] === "development") {
        console.warn("FOUND UNHANDLED CHARACTER:", letter);
      }

      return (
        <div key={gindex} className="letter-container special middle">
          <div>{letter}</div>
        </div>
      );
    }
  }

  let gameBoardStyle = {};
  if (zoomLevel !== undefined && zoomLevel !== "100") {
    let zoomAmount = `${zoomLevel}%`;
    gameBoardStyle = {
      zoom: zoomAmount,
    };
  }

  return (
    <div className={"gameboard"} style={gameBoardStyle}>
      {words.map((word, wordIndex) => {
        if (wordIndex > 0) {
          ctr++;
        }

        return (
          <div key={wordIndex} className="word-container">
            {word.split("").map((letter, index) => {
              const gindex = ctr;
              ctr++;

              const originalLetter = initialSentence[gindex];

              let shouldShowNumber;
              let hasSomeGuessed;

              // optimize this later...
              if (
                difficulty === Difficulties.Easy ||
                difficulty === Difficulties.Medium ||
                difficulty === Difficulties.Advanced
              ) {
                shouldShowNumber = notAllOccurrencesGuessed(
                  originalLetter,
                  sentence.join(""),
                  initialSentence
                );
                hasSomeGuessed = hasSomeOccurrencesGuessed(
                  originalLetter,
                  sentence.join(""),
                  initialSentence
                );
              } else if (
                difficulty === Difficulties.Hard ||
                difficulty === Difficulties.Challenging ||
                difficulty === Difficulties.Expert ||
                difficulty === Difficulties.Hardcore
              ) {
                shouldShowNumber = hasSomeOccurrencesGuessed(
                  originalLetter,
                  sentence.join(""),
                  initialSentence
                );
              }

              return letterButton(
                letter,
                gindex,
                shouldShowNumber,
                hasSomeGuessed,
                originalLetter
              );
            })}
          </div>
        );
      })}
    </div>
  );
}

export default Gameboard;
