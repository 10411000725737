import React, { useState, useEffect, useCallback, useRef } from "react";
import "./GameHolder.css";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import Keyboard from "./Keyboard";
import Gameboard from "./Gameboard";
import { livesPerDifficulty } from "../settings/DifficultySlider";
import ThemeSelector from "./ThemeSelector";

import {
  createInitialSentence,
  generateRandomMapping,
  findNextBlank,
  getNiceTime,
  printLives,
} from "../helpers/utils";
import ThemeManager from "./ThemeManager";
import { Spinner } from "react-bootstrap";
import KeyboardInput from "../helpers/KeyboardInput";

function GameHolder({ difficulty, zoomLevel, enableHwKeyboard, showInfo }) {
  const [themes, setThemes] = useState({});
  const [theme, setTheme] = useState("");
  const [themeLoaded, setThemeLoaded] = useState(false);
  const [sentencesLoaded, setSentencesLoaded] = useState(false);
  const [level, setLevel] = useState(0);
  const [sentence, setSentence] = useState("");
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [message, setMessage] = useState("");
  const [letterMapping, setLetterMapping] = useState({});
  const [isGameCompleted, setIsGameCompleted] = useState(false);
  const [isGameOver, setIsGameOver] = useState(false);

  const [wrongIndex, setWrongIndex] = useState(undefined);

  const [lives, setLives] = useState(undefined);

  const [startTime, setStartTime] = useState(null);
  const [elapsedTime, setElapsedTime] = useState(null);

  const themeRef = useRef(theme);
  const levelRef = useRef(level);

  const handleThemesLoaded = useCallback((loadedThemes) => {
    if (loadedThemes === undefined) {
      setMessage("Error loading themes 😭 Please try again later 😞");
    } else {
      setThemes(loadedThemes);
      const firstTheme = Object.keys(loadedThemes)[0];
      setTheme(firstTheme);
      themeRef.current = firstTheme;
      setThemeLoaded(true);
      setMessage("");
    }
  }, []);

  useEffect(() => {
    if (!themeLoaded) {
      return;
    }

    setSentencesLoaded(
      themes[theme] !== undefined && themes[theme].sentences !== undefined
    );
  }, [themeLoaded, themes, theme]);

  const handleSentencesLoaded = useCallback(
    (loadedSentences, themeKey) => {
      if (themeLoaded) {
        if (loadedSentences === undefined) {
          setMessage("Error loading sentences 😭 Please try again later 😞");
        } else {
          if (themes[themeKey] !== undefined) {
            themes[themeKey].sentences = loadedSentences;
            if (themeKey === themeRef.current) {
              setSentencesLoaded(true);
            }
          }
        }
      }
    },
    [themeLoaded, themes]
  );

  useEffect(() => {
    if (isGameOver === false) {
      setLetterMapping(generateRandomMapping());
    }
  }, [theme, level, difficulty, isGameCompleted, isGameOver]);

  useEffect(() => {
    if (sentencesLoaded && isGameOver === false && sentence === "") {
      setSentence(
        createInitialSentence(
          themes[themeRef.current].sentences[levelRef.current],
          difficulty
        )
      );
    }
  }, [sentencesLoaded, themes, theme, level, difficulty, isGameOver, sentence]);

  function handleSelectLetter(index) {
    // Only allow selecting if it's a blank space
    if (sentence[index] === "_") {
      startTimer();
      setSelectedIndex(index);
      setMessage(""); // Reset the message
    }
  }

  const selectNextBlank = useCallback(() => {
    const nextBlankIndex = findNextBlank(sentence, selectedIndex, 1);
    setSelectedIndex(nextBlankIndex !== -1 ? nextBlankIndex : null);
    startTimer();
  }, [selectedIndex, sentence]);

  const selectPrevBlank = useCallback(() => {
    const prevBlankIndex = findNextBlank(sentence, selectedIndex, -1);
    setSelectedIndex(prevBlankIndex !== -1 ? prevBlankIndex : null);
    startTimer();
  }, [selectedIndex, sentence]);

  const startTimeRef = useRef(startTime);

  function startTimer() {
    if (startTimeRef.current === null) {
      let now = Date.now();
      setStartTime(now);
      startTimeRef.current = now;
      setElapsedTime(null);
    }
  }

  useEffect(() => {
    if (isGameCompleted) {
      // Stop the timer and calculate the elapsed time
      const currentTime = Date.now();

      let elapsed = getNiceTime(currentTime, startTimeRef.current);
      setElapsedTime(elapsed);
    }
  }, [isGameCompleted]);

  const handleKeyPress = useCallback(
    (letter) => {
      if (selectedIndex === null) {
        selectNextBlank();
        return;
      }

      if (isGameCompleted) return;

      if (
        themes[themeRef.current].sentences[levelRef.current][
          selectedIndex
        ].toLowerCase() === letter.toLowerCase()
      ) {
        let newSentence = [...sentence];
        // Replace only the letter at the selected index
        newSentence[selectedIndex] =
          themes[themeRef.current].sentences[levelRef.current][selectedIndex];
        setSentence(newSentence);
        selectNextBlank(); // Automatically select the next blank

        const allFilled = !newSentence.includes("_");
        if (allFilled) {
          setIsGameCompleted(true);
          setSelectedIndex(null);

          if (
            levelRef.current <
            themes[themeRef.current].sentences.length - 1
          ) {
            setMessage("Congratulations! You've guessed the sentence! 🥳");
          } else {
            setMessage("You've completed all levels! Congratulations! 🥳");
          }
        }
        setWrongIndex(undefined);
      } else {
        setWrongIndex(selectedIndex);
        if (lives === 1) {
          setMessage("Oh no 😭 Better luck next time!");
          setLives(lives - 1);
          setIsGameOver(true);
        } else {
          setLives(lives - 1);
        }
      }
    },
    [selectedIndex, isGameCompleted, lives, sentence, themes, selectNextBlank]
  );

  const resetGameboard = useCallback(() => {
    if (difficulty === undefined) return;

    if (isGameCompleted) return;

    setSelectedIndex(null);
    setIsGameCompleted(false);
    setIsGameOver(false);
    setMessage("");
    setSentence("");
    setStartTime(null);
    startTimeRef.current = null;
    setLives(livesPerDifficulty[difficulty]);
  }, [difficulty, isGameCompleted]);

  useEffect(() => {
    difficultyRef.current = difficulty;
    resetGameboard();
  }, [difficulty, resetGameboard]);

  const handleNextLevel = useCallback(() => {
    if (levelRef.current < themes[themeRef.current].sentences.length - 1) {
      setLevel(level + 1);
      levelRef.current++;
      setIsGameCompleted(false);
      resetGameboard();
    }
  }, [resetGameboard, level, themes]);

  const handleThemeChange = (event) => {
    const newTheme = event.target.value;
    setTheme(newTheme);
    themeRef.current = newTheme;
    setLevel(0);
    levelRef.current = 0;
    setIsGameCompleted(false);
    resetGameboard();
    setSentencesLoaded(false);
  };

  const handleOnContinue = useCallback(() => {
    if (isGameOver) {
      resetGameboard();
    } else if (isGameCompleted) {
      handleNextLevel();
    }
  }, [isGameCompleted, isGameOver, resetGameboard, handleNextLevel]);

  const difficultyRef = useRef(difficulty);

  return (
    <>
      <ThemeManager
        onThemesLoaded={handleThemesLoaded}
        onSentencesLoaded={handleSentencesLoaded}
        currentTheme={themes[themeRef.current]}
        themeKey={themeRef.current}
      />
      {enableHwKeyboard === true &&
        showInfo === false &&
        themeLoaded &&
        sentencesLoaded &&
        sentence.length > 0 && (
          <KeyboardInput
            onAlphabetic={handleKeyPress}
            onArrowLeft={selectPrevBlank}
            onArrowRight={selectNextBlank}
            onContinue={handleOnContinue}
            enableContinue={isGameOver || isGameCompleted}
          />
        )}

      {showInfo === false && (
        <>
          <Row>
            <Col
              style={{
                display: "inline-block",
              }}
            >
              {themeLoaded && (
                <ThemeSelector
                  themes={themes}
                  theme={themeRef.current}
                  onThemeChange={handleThemeChange}
                />
              )}
            </Col>
          </Row>

          {themeLoaded && sentencesLoaded && (
            <Row>
              <Col
                style={{
                  display: "inline-block",
                }}
              >
                <div className="levelHeader">
                  Level {levelRef.current + 1} of{" "}
                  {themes[themeRef.current].sentences.length}
                </div>
              </Col>
              <Col
                style={{
                  marginLeft: "20px",
                  display: "inline-block",
                }}
              >
                Lives:{" "}
                {printLives(lives, livesPerDifficulty[difficultyRef.current])}
              </Col>
            </Row>
          )}

          <Row className="gameboard-container">
            {themeLoaded &&
              sentencesLoaded &&
              themes[themeRef.current].sentences[levelRef.current].length ===
                sentence.length && (
                <Gameboard
                  sentence={sentence}
                  letterMapping={letterMapping}
                  selectedIndex={selectedIndex}
                  onSelectLetter={handleSelectLetter}
                  onGuessLetter={handleKeyPress}
                  initialSentence={
                    themes[themeRef.current].sentences[levelRef.current]
                  }
                  difficulty={difficulty}
                  zoomLevel={zoomLevel}
                  wrongIndex={wrongIndex}
                />
              )}
            {themeLoaded === false ||
              (sentencesLoaded === false && (
                <div className="loadingLevel">
                  <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading level ⏳...</span>
                  </Spinner>
                </div>
              ))}
          </Row>

          {message !== "" && (
            <Row>
              <p>{message}</p>
            </Row>
          )}

          <Row>
            {isGameCompleted && (
              <div className="levelTime">
                <p>Time: {elapsedTime}</p>
              </div>
            )}
          </Row>
          {isGameCompleted &&
            themes[themeRef.current] !== undefined &&
            level < themes[themeRef.current].sentences.length - 1 && (
              <Row className="flex-middle">
                <button
                  className="goodButton nextLevel"
                  onClick={handleNextLevel}
                >
                  <div className="title"> Next Level</div>
                  <div className="returnKey">↵</div>
                </button>
              </Row>
            )}

          {isGameOver && (
            <Row className="flex-middle">
              <button
                className="goodButton restartLevel"
                onClick={resetGameboard}
              >
                <div className="title">Restart</div>
                <div className="returnKey">↵</div>
              </button>
            </Row>
          )}

          <Row className="keyboard-container">
            {themeLoaded && sentencesLoaded && sentence.length > 0 && (
              <Keyboard
                onKeyPress={handleKeyPress}
                sentence={sentence}
                initialSentence={themes[theme].sentences[level]}
                isGameCompleted={isGameCompleted}
                isGameOver={isGameOver}
                difficulty={difficulty}
                selectNextBlank={selectNextBlank}
                selectPrevBlank={selectPrevBlank}
              />
            )}
          </Row>
        </>
      )}
    </>
  );
}

export default GameHolder;
