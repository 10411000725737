import { useEffect } from "react";
import "./ZoomSelector.css";

const ZoomSelectorState = ({ setZoomLevel }) => {
  useEffect(() => {
    let zoom;
    const savedPreference = localStorage.getItem("zoom");
    if (savedPreference !== null && savedPreference !== "undefined") {
      zoom = JSON.parse(savedPreference);
    } else {
      zoom = "100";
    }
    localStorage.setItem("zoom", JSON.stringify(zoom));
    setZoomLevel(zoom);
  });

  return null;
};

export default ZoomSelectorState;
