import ExplanationWrapper from "./ExplanationWrapper";

function Privacy() {
  return (
    <ExplanationWrapper title="Privacy policy">
      <p>
        When you open the website, your IP address is saved in the logs of the
        webserver. These logs are used to analyze the general performance of the
        website and to prevent malicious usage.
      </p>
    </ExplanationWrapper>
  );
}

export default Privacy;
