import { Row } from "react-bootstrap";
import "./ExplanationWrapper.css"; // Make sure to create this CSS file

function ExplanationWrapper({ title, children }) {
  return (
    <>
      <Row className="flex-middle">
        <div className="explanation">
          <h2>{title}</h2>
          {children}
        </div>
      </Row>
    </>
  );
}

export default ExplanationWrapper;
