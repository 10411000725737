import CountdownToTenCEST from "../helpers/CountdownToTenCEST";
import ExplanationWrapper from "./ExplanationWrapper";

function NewThemes() {
  return (
    <ExplanationWrapper title="New themes">
      <p>
        Every day at 10:00 CEST,
        <CountdownToTenCEST />
      </p>
    </ExplanationWrapper>
  );
}

export default NewThemes;
