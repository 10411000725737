import React, { useEffect, useState } from "react";
import "./DifficultySlider.css"; // Add any specific styles for the slider

export const Difficulties = {
  Easy: "Easy",
  Medium: "Medium",
  Advanced: "Advanced",
  Hard: "Hard",
  Expert: "Expert",
  Challenging: "Challenging",
  Hardcore: "Hardcore",
};

const listOfDifficulties = [
  Difficulties.Easy,
  Difficulties.Medium,
  Difficulties.Advanced,
  Difficulties.Hard,
  Difficulties.Challenging,
  Difficulties.Expert,
  Difficulties.Hardcore,
];

export const livesPerDifficulty = {};

livesPerDifficulty[Difficulties.Easy] = undefined;
livesPerDifficulty[Difficulties.Medium] = 3;
livesPerDifficulty[Difficulties.Advanced] = 3;
livesPerDifficulty[Difficulties.Hard] = 3;
livesPerDifficulty[Difficulties.Challenging] = 3;
livesPerDifficulty[Difficulties.Expert] = 3;
livesPerDifficulty[Difficulties.Hardcore] = 1;

function DifficultySlider({ difficulty, setDifficulty }) {
  const [localDifficulty, setLocalDifficulty] = useState(difficulty);

  useEffect(() => {
    localStorage.setItem("difficulty", JSON.stringify(localDifficulty));
    setDifficulty(localDifficulty);
  }, [localDifficulty, setDifficulty]);

  function handleDifficultyChange(difficulty) {
    setLocalDifficulty(difficulty);
    setDifficulty(difficulty);
  }

  function handleBlur() {
    this.blur();
  }

  useEffect(() => {
    const ref = document.getElementById("difficulty");
    ref.addEventListener("change", handleBlur);

    return () => {
      ref.removeEventListener("change", handleBlur);
    };
  });

  return (
    <div className="difficulty-slider">
      <label htmlFor="difficulty">Difficulty: </label>
      <input
        type="range"
        id="difficulty"
        min="0"
        max="6"
        step="1"
        value={listOfDifficulties.indexOf(localDifficulty)}
        onChange={(e) =>
          handleDifficultyChange(listOfDifficulties[e.target.value])
        }
      />
      <div className="difficultySetting">{localDifficulty}</div>
    </div>
  );
}

export default DifficultySlider;
