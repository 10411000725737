import About from "./About";
import Controls from "./Controls";
import Rules from "./Rules";
import Privacy from "./Privacy";
import Difficulties from "./Difficulties";
import NewThemes from "./NewThemes";

function Explanations() {
  return (
    <>
      <NewThemes />
      <Difficulties />
      <Rules />
      <Controls />
      <About />
      <Privacy />
    </>
  );
}

export default Explanations;
