import React, { useState, useEffect } from "react";
import "./ThemeToggle.css";

function ThemeToggle() {
  const [isDarkMode, setIsDarkMode] = useState(() => {
    const savedPreference = localStorage.getItem("isDarkMode");
    if (savedPreference !== null) {
      return JSON.parse(savedPreference);
    }
    const prefersDarkMode = window.matchMedia(
      "(prefers-color-scheme: dark)"
    ).matches;
    return prefersDarkMode;
  });

  useEffect(() => {
    if (isDarkMode) {
      document.body.classList.add("dark-mode");
    } else {
      document.body.classList.remove("dark-mode");
    }
    localStorage.setItem("isDarkMode", JSON.stringify(isDarkMode));
  }, [isDarkMode]);

  function handleBlur() {
    this.blur();
  }

  useEffect(() => {
    const ref = document.getElementById("theme-input");
    ref.addEventListener("change", handleBlur);

    return () => {
      ref.removeEventListener("change", handleBlur);
    };
  });

  return (
    <div className="theme-toggle">
      <span className="theme-icon">{isDarkMode ? "🌙" : "🌞"}</span>
      <label className="toggle-switch">
        <input
          id="theme-input"
          type="checkbox"
          checked={isDarkMode}
          onChange={() => setIsDarkMode(!isDarkMode)}
        />
        <span className="slider"></span>
      </label>
    </div>
  );
}

export default ThemeToggle;
