import { useEffect } from "react";

const Umami = () => {
  function cookie(e) {
    if (process.env.REACT_APP_BETA === "true") {
      localStorage.setItem("umami.disabled", 1);
    }

    // Create the script element
    const script = document.createElement("script");
    script.src = "https://cloud.umami.is/script.js";
    script.setAttribute(
      "data-website-id",
      "0531d5f5-81e9-45b0-9576-6d93485aee21"
    );
    script.defer = true;

    if (window.Cookiebot.consent.marketing) {
      document.head.appendChild(script);
    }
  }

  useEffect(() => {
    // Check if we are in production mode
    if (process.env.NODE_ENV === "production") {
      window.addEventListener("CookiebotOnAccept", cookie, false);

      // Append the script to the head

      // Cleanup: remove script if the component unmounts
      return () => {
        window.removeEventListener("CookiebotOnAccept", cookie);
      };
    }
  }); // Empty dependency array ensures this runs only once

  return null; // This component doesn't render anything
};

export default Umami;
