import ExplanationWrapper from "./ExplanationWrapper";

function About() {
  return (
    <ExplanationWrapper title="About">
      <p>Make with 💖 by Nicolaj from Variable.dk &copy;2024</p>
      <p>Content is based on works that are Public Domain</p>
    </ExplanationWrapper>
  );
}

export default About;
