import React from "react";
import "./Keyboard.css"; // Add any specific styles for the keyboard
import { Difficulties } from "../settings/DifficultySlider";

// Define the keyboard layout
const keyboardRows = ["QWERTYUIOP", "ASDFGHJKL", "ZXCVBNM"];

function Keyboard({
  onKeyPress,
  sentence,
  initialSentence,
  isGameCompleted,
  isGameOver,
  difficulty,
  selectNextBlank,
  selectPrevBlank,
}) {
  const disableKeys = isGameCompleted || isGameOver;

  function areAllOccurrencesGuessed(letter) {
    const upperLetter = letter.toUpperCase();
    const occurrences = initialSentence
      .split("")
      .filter((char) => char.toUpperCase() === upperLetter).length;
    const guessedOccurrences = sentence
      .join("")
      .split("")
      .filter((char) => char.toUpperCase() === upperLetter).length;
    return occurrences === guessedOccurrences;
  }
  function occurAndAllOccurrencesGuessed(letter) {
    const upperLetter = letter.toUpperCase();
    const occurrences = initialSentence
      .split("")
      .filter((char) => char.toUpperCase() === upperLetter).length;
    const guessedOccurrences = sentence
      .join("")
      .split("")
      .filter((char) => char.toUpperCase() === upperLetter).length;
    return occurrences > 0 && occurrences === guessedOccurrences;
  }

  function hasSomeOccurrencesGuessed(letter) {
    const upperLetter = letter.toUpperCase();
    const occurrences = initialSentence
      .split("")
      .filter((char) => char.toUpperCase() === upperLetter).length;
    const guessedOccurrences = sentence
      .join("")
      .split("")
      .filter((char) => char.toUpperCase() === upperLetter).length;
    return guessedOccurrences > 0 && occurrences > guessedOccurrences;
  }

  function isButtonDisabled(letter) {
    if (
      difficulty === Difficulties.Easy ||
      difficulty === Difficulties.Medium ||
      difficulty === Difficulties.Hard
    ) {
      return areAllOccurrencesGuessed(letter) || disableKeys;
    } else if (
      difficulty === Difficulties.Advanced ||
      difficulty === Difficulties.Challenging
    ) {
      return occurAndAllOccurrencesGuessed(letter) || disableKeys;
    } else {
      return disableKeys;
    }
  }

  function getButtonClass(letter) {
    if (
      difficulty === Difficulties.Easy ||
      difficulty === Difficulties.Medium ||
      difficulty === Difficulties.Advanced
    ) {
      return !isButtonDisabled(letter) && hasSomeOccurrencesGuessed(letter)
        ? "guessed"
        : "";
    } else {
      return "";
    }
  }

  function leftKey(rowIndex) {
    if (rowIndex === keyboardRows.length - 1) {
      return (
        <button
          key="leftKey"
          onClick={selectPrevBlank}
          className="arrowKey left"
        >
          ←
        </button>
      );
    } else {
      return null;
    }
  }
  function rightKey(rowIndex) {
    if (rowIndex === keyboardRows.length - 1) {
      return (
        <button
          key="rightKey"
          onClick={selectNextBlank}
          className="arrowKey right"
        >
          →
        </button>
      );
    } else {
      return null;
    }
  }

  return (
    <div className={`keyboard ${disableKeys ? "faded" : ""}`}>
      {keyboardRows.map((row, rowIndex) => (
        <div key={rowIndex} className="keyboard-row">
          {leftKey(rowIndex)}
          {row.split("").map((letter) => (
            <button
              key={letter}
              onClick={() => onKeyPress(letter)}
              disabled={isButtonDisabled(letter)}
              className={getButtonClass(letter)}
            >
              {letter}
            </button>
          ))}
          {rightKey(rowIndex)}
        </div>
      ))}
    </div>
  );
}

export default Keyboard;
