import React, { useState, useEffect } from "react";

const CountdownToTenCEST = () => {
  const [timeRemaining, setTimeRemaining] = useState("");
  const [targetTimeReached, setTargetTimeReached] = useState(false);

  useEffect(() => {
    const countdownToNextTenCEST = () => {
      // Get the current time in the user's timezone
      const now = new Date();

      // Convert the current time to CEST (Central European Summer Time)
      const currentOffset = now.getTimezoneOffset() * 60000;
      const cestOffset = 2 * 60 * 60 * 1000; // 2 hours ahead of UTC during CEST
      const cestNow = new Date(now.getTime() + currentOffset + cestOffset);

      // Calculate the next 10:00 AM CEST time
      let targetTime = new Date(cestNow);
      targetTime.setHours(10, 0, 0, 0); // Set target to 10:00 AM

      // If the current time is already past 10:00 AM, set the target to the next day
      if (cestNow.getHours() >= 10) {
        targetTime.setDate(targetTime.getDate() + 1);
      }

      const updateClock = () => {
        const now = new Date();
        const timeRemaining = targetTime - now;

        if (timeRemaining <= 0) {
          clearInterval(interval);
          setTargetTimeReached(true);
        } else {
          // Calculate hours, minutes, and seconds remaining
          const hours = Math.floor((timeRemaining / (1000 * 60 * 60)) % 24);
          const minutes = Math.floor((timeRemaining / (1000 * 60)) % 60);
          const seconds = Math.floor((timeRemaining / 1000) % 60);

          // Update the state with the remaining time
          setTimeRemaining(`${hours}h ${minutes}m ${seconds}s`);
        }
      };

      updateClock();

      // Update the countdown every second
      const interval = setInterval(updateClock, 1000);

      return () => clearInterval(interval); // Clear the interval on component unmount
    };

    countdownToNextTenCEST();
  }, []);

  return (
    <> {targetTimeReached ? "New themes are live!" : "in " + timeRemaining}</>
  );
};

export default CountdownToTenCEST;
