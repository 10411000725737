import React, { useEffect } from "react";

const ThemeSelector = ({ themes, theme, onThemeChange }) => {
  function handleBlur() {
    this.blur();
  }

  useEffect(() => {
    const ref = document.getElementById("theme");
    ref.addEventListener("change", handleBlur);

    return () => {
      ref.removeEventListener("change", handleBlur);
    };
  });

  return (
    <div className="themeSelector">
      <label htmlFor="theme">Choose a theme: </label>
      <select id="theme" value={theme} onChange={onThemeChange}>
        {Object.keys(themes).map((themeKey) => (
          <option key={themeKey} value={themeKey}>
            {themeKey}
          </option>
        ))}
      </select>
    </div>
  );
};

export default ThemeSelector;
