import { useEffect } from "react";

function HWKeyboardState({ setEnableHwKeyboard }) {
  useEffect(() => {
    let enableHWKeyboad;
    const savedPreference = localStorage.getItem("enableHWKeyboad");
    if (savedPreference !== null && savedPreference !== "undefined") {
      enableHWKeyboad = JSON.parse(savedPreference);
    } else {
      enableHWKeyboad = true;
    }

    localStorage.setItem("enableHWKeyboad", JSON.stringify(enableHWKeyboad));
    setEnableHwKeyboard(enableHWKeyboad);
  }, [setEnableHwKeyboard]);

  return null;
}

export default HWKeyboardState;
