import { useState } from "react";
import GameHolder from "./components/game/GameHolder";
import DifficultySliderState from "./components/settings/DifficultySliderState";
import Container from "react-bootstrap/Container";

import HWKeyboardState from "./components/settings/HWKeyboardState";
import ZoomSelectorState from "./components/settings/ZoomSelectorState";
import InfoToggle from "./components/toggles/InfoToggle";
import InfoPage from "./components/InfoPage";
import { Row } from "react-bootstrap";
import ThemeToggle from "./components/toggles/ThemeToggle";
import CharsetManager from "./components/helpers/charsetManager";

function App() {
  const [difficulty, setDifficulty] = useState(undefined);
  const [zoomLevel, setZoomLevel] = useState(undefined);
  const [enableHwKeyboard, setEnableHwKeyboard] = useState(undefined);

  const [showInfo, setShowInfo] = useState(false);

  return (
    <>
      <HWKeyboardState setEnableHwKeyboard={setEnableHwKeyboard} />
      <ZoomSelectorState setZoomLevel={setZoomLevel} />
      <DifficultySliderState setDifficulty={setDifficulty} />
      <InfoToggle
        onInfoToggle={() => {
          setShowInfo(!showInfo);
        }}
      />
      <ThemeToggle />

      {process.env["NODE_ENV"] === "development" && <CharsetManager />}

      <Container className={showInfo ? "" : "gamemode"}>
        <Row>
          <h1>Wortigo!</h1>
        </Row>

        {showInfo === true && (
          <InfoPage
            enableHwKeyboard={enableHwKeyboard}
            setEnableHwKeyboard={setEnableHwKeyboard}
            zoomLevel={zoomLevel}
            setZoomLevel={setZoomLevel}
            difficulty={difficulty}
            setDifficulty={setDifficulty}
          />
        )}

        <GameHolder
          difficulty={difficulty}
          zoomLevel={zoomLevel}
          enableHwKeyboard={enableHwKeyboard}
          showInfo={showInfo}
        />
      </Container>
    </>
  );
}

export default App;
