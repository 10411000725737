import React, { useEffect, useState } from "react";
import "./ZoomSelector.css";

const zoomLevels = [150, 120, 100, 90, 80, 70];

const ZoomSelector = ({ zoom, setZoomLevel }) => {
  const [localZoom, setLocalZoom] = useState(zoom);

  useEffect(() => {
    localStorage.setItem("zoom", JSON.stringify(localZoom));
    setZoomLevel(localZoom);
  }, [localZoom, setZoomLevel]);

  function handleZoomChange(event) {
    setLocalZoom(event.target.value);
    setZoomLevel(event.target.value);
  }

  function handleBlur() {
    this.blur();
  }

  useEffect(() => {
    const ref = document.getElementById("zoom");
    ref.addEventListener("change", handleBlur);

    return () => {
      ref.removeEventListener("change", handleBlur);
    };
  });

  return (
    <div className="zoomSelector">
      <label htmlFor="zoom">Zoom: </label>
      <select id="zoom" value={localZoom} onChange={handleZoomChange}>
        {zoomLevels.map((level) => (
          <option key={level} value={level}>
            {level}%
          </option>
        ))}
      </select>
    </div>
  );
};

export default ZoomSelector;
