import { useCallback, useEffect } from "react";
import { isAlphabetic } from "./utils";

const KeyboardInput = ({
  onAlphabetic,
  onArrowLeft,
  onArrowRight,
  onContinue,
  enableContinue,
}) => {
  const handleKeyPress = useCallback(
    (event) => {
      const key = event.key;

      if (enableContinue) {
        if (key === "Enter") {
          onContinue();
        }
      } else {
        if (key.length === 1 && isAlphabetic(key)) {
          onAlphabetic(key);
        } else if (key === "ArrowLeft") {
          onArrowLeft();
        } else if (key === "ArrowRight") {
          onArrowRight();
        }
      }
    },
    [onAlphabetic, onArrowLeft, onArrowRight, onContinue, enableContinue]
  );

  useEffect(() => {
    // Add event listener when the component mounts
    window.addEventListener("keyup", handleKeyPress);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("keyup", handleKeyPress);
    };
  }, [handleKeyPress]); // Empty dependency array ensures this runs once

  return null;
};

export default KeyboardInput;
