import React, { useState, useEffect } from "react";
import "./HWKeyboardToggle.css";
import { Row } from "react-bootstrap";

function HWKeyboardToggle({ enableHwKeyboard, setEnableHwKeyboard }) {
  const [localEnableHwKeyboard, setLocalEnableHwKeyboard] =
    useState(enableHwKeyboard);

  useEffect(() => {
    localStorage.setItem(
      "enableHWKeyboad",
      JSON.stringify(localEnableHwKeyboard)
    );
    setEnableHwKeyboard(localEnableHwKeyboard);
  }, [localEnableHwKeyboard, setEnableHwKeyboard]);

  function handleHwKeyboardChange(event) {
    setLocalEnableHwKeyboard(!localEnableHwKeyboard);
    setEnableHwKeyboard(!localEnableHwKeyboard);
  }

  return (
    <Row className="flex-middle">
      <div className="hwkeyboard-toggle">
        <span className="hwkeyboard-text">Enable the pc keyboard</span>
        <span className="hwkeyboard-icon">⌨️</span>
        <label className="hwkeyboard-switch">
          <input
            type="checkbox"
            checked={localEnableHwKeyboard}
            onChange={handleHwKeyboardChange}
          />
          <span className="slider"></span>
        </label>
      </div>
    </Row>
  );
}

export default HWKeyboardToggle;
