import React, { useEffect } from "react";
import "./InfoToggle.css";

function InfoToggle({ onInfoToggle }) {
  function handleBlur() {
    this.blur();
  }

  useEffect(() => {
    const ref = document.getElementById("info-toggle");
    ref.addEventListener("click", handleBlur);

    return () => {
      ref.removeEventListener("click", handleBlur);
    };
  });

  return (
    <div className="info-toggle">
      <button
        id="info-toggle"
        className="goodButton toggle-button"
        onClick={() => onInfoToggle()}
      >
        Info
      </button>
    </div>
  );
}

export default InfoToggle;
