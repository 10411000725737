import { useEffect } from "react";

const Cookiebot = () => {
  useEffect(() => {
    // Check if we are in production mode
    if (process.env.NODE_ENV === "production") {
      // Create the script element
      const script = document.createElement("script");
      script.src = "https://consent.cookiebot.com/uc.js";
      script.setAttribute("data-cbid", "dd529892-0637-4cef-88cc-99c87ef7277a");
      script.setAttribute("data-blockingmode", "auto");
      script.setAttribute("type", "text/javascript");

      script.id = "Cookiebot";

      // Append the script to the head
      document.head.appendChild(script);

      // Cleanup: remove script if the component unmounts
      return () => {
        document.head.removeChild(script);
      };
    }
  }, []); // Empty dependency array ensures this runs only once

  return null; // This component doesn't render anything
};

export default Cookiebot;
