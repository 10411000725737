import { useEffect, useState } from "react";
import axios from "axios";
import { formatIsoDate } from "../helpers/utils";

const ThemeManager = ({
  onThemesLoaded,
  onSentencesLoaded,
  currentTheme,
  themeKey,
}) => {
  const [themes, setThemes] = useState([]);

  const BACKEND_ADDR = process.env["REACT_APP_BACKEND_ADDR"] || "/server";

  useEffect(() => {
    // Fetch the themes from the Express server
    axios
      .post(`${BACKEND_ADDR}/themes`)
      .then((response) => {
        if (response.data.error === undefined) {
          setThemes(response.data);
        } else {
          console.log(response.data.error);
          setThemes(undefined);
        }
      })
      .catch((error) => {
        console.error("There was an error fetching the themes!", error);
      });
  }, [BACKEND_ADDR]);

  useEffect(() => {
    if (currentTheme === undefined) return;

    if (currentTheme.sentences === undefined) {
      // console.log("current theme has no sentences yet");

      const data = {
        id: currentTheme.uuid,
      };
      axios
        .post(`${BACKEND_ADDR}/theme`, data)
        .then((response) => {
          if (response.data.error === undefined) {
            onSentencesLoaded(response.data.sentences, themeKey);
          } else {
            onSentencesLoaded(undefined, undefined);
          }
        })
        .catch((error) => {
          console.error("There was an error fetching the themes!", error);
        });
    }
  }, [currentTheme, BACKEND_ADDR, onSentencesLoaded, themeKey]);

  useEffect(() => {
    async function fetchThemes() {
      if (themes === undefined) {
        onThemesLoaded(undefined);
        return;
      }

      if (themes.length === 0) return;

      try {
        let themesObj = {};

        for (const uuid in themes) {
          try {
            const theme = themes[uuid];

            let after = Date.parse(theme.after);

            if (isNaN(after)) {
              throw new Error("Failed to parse time");
            }
            const d = new Date(after);
            const isoDate = formatIsoDate(d);
            themesObj[`${isoDate}: ${theme.name}`] = {
              uuid: uuid,
              sentences: theme.sentences,
            };
          } catch (err) {
            console.log("Error parsing after for", uuid);
          }
        }

        if (process.env["NODE_ENV"] === "development") {
          // console.log("Loaded:", themesObj);
          const test = {};
          test["test"] = {
            uuid: "test",
            sentences: [
              "The cou2nt's pr3e&s(e )nce, ve*ry cool! e-ma9il; and special: chars" +
                "The cou2nt's pr3e&s(e )nce, ve*ry cool! e-ma9il; and special: chars" +
                "The cou2nt's pr3e&s(e )nce, ve*ry cool! e-ma9il; and special: chars" +
                "The cou2nt's pr3e&s(e )nce, ve*ry cool! e-ma9il; and special: chars" +
                "The cou2nt's pr3e&s(e )nce, ve*ry cool! e-ma9il; and special: chars" +
                "The cou2nt's pr3e&s(e )nce, ve*ry cool! e-ma9il; and special: chars" +
                "The cou2nt's pr3e&s(e )nce, ve*ry cool! e-ma9il; and special: chars",
              "test,.-*!?''",
              "test",
              "test",
            ],
          };
          themesObj = Object.assign({}, test, themesObj);
        }

        onThemesLoaded(themesObj); // Notify parent that themes are loaded
      } catch (error) {
        console.error("Error loading themes:", error);
      }
    }

    fetchThemes();
  }, [onThemesLoaded, themes]);

  return null;
};

export default ThemeManager;
