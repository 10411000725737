import { useEffect } from "react";
import "./DifficultySlider.css"; // Add any specific styles for the slider
import { Difficulties } from "./DifficultySlider";

function DifficultySliderState({ setDifficulty }) {
  useEffect(() => {
    let difficulty;
    const savedPreference = localStorage.getItem("difficulty");
    if (savedPreference !== null) {
      difficulty = JSON.parse(savedPreference);
    } else {
      difficulty = Difficulties.Medium;
    }
    localStorage.setItem("difficulty", JSON.stringify(difficulty));
    setDifficulty(difficulty);
  });

  return null;
}

export default DifficultySliderState;
