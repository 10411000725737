import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import Umami from "./analytics/umami";
import Cookiebot from "./analytics/CookieBot";
import App from "./App";

console.log("build: " + process.env["REACT_APP_GIT_SHA"]);

if (process.env["REACT_APP_BETA"] === "true") {
  document.title = document.title + " - BETA";
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Cookiebot />
    <Umami />
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
