import ExplanationWrapper from "./ExplanationWrapper";

function Controls() {
  return (
    <ExplanationWrapper title="Controls">
      <p>You may either use the on-screen keyboard, or an attached keyboard.</p>
      <p>
        Left and right arrow key changes selection and A-Z will guess a letter.
      </p>
      <p>
        In the beginning when nothing is selected a guess will select the first
        blank space.
      </p>
      <p>
        After completing a level or running out of lives, the enter key can be
        used to restart or go to the next level.
      </p>
    </ExplanationWrapper>
  );
}

export default Controls;
