import ExplanationWrapper from "./ExplanationWrapper";

function Rules() {
  return (
    <ExplanationWrapper title="Rules">
      <p>The objective of the game is to guess a sentence.</p>
      <p>
        Some letters are revealed in the beginning, and depending on the
        difficulty, some or all of these will have numbers below.
      </p>
      <p>
        The numbers correspond to a specific letter, but they change from game
        to game.
      </p>
      <p>
        To guess a letter, select it in the sentence and use the keyboard to
        make a guess.
      </p>
      <p>The time starts when you select the first blank letter.</p>
    </ExplanationWrapper>
  );
}

export default Rules;
